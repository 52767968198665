import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCustomerSupportEmail, useCustomerSupportNumber } from 'app/hooks/store/use-customer-support.hook';
import { InvitationAction, InvitationViews, RejectInvitationDialog } from 'features/invitation';
import { history } from 'store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { processInvitation } from 'api/userManagement';
import { SeverityType, useSnackbar } from '@chr/eds-react';

import * as Styles from 'features/invitation/styles';

interface AcceptOrRejectInvitationProps {
 data: ValidateInvitationTokenResponse;
 setView: Dispatch<SetStateAction<symbol>>;
}

export const AcceptOrRejectInvitation = ({ data, setView }: AcceptOrRejectInvitationProps) => {
 const [isRejectInvitationDialogOpen, setIsRejectInvitationDialogOpen] = useState(false);
 const [isAcceptOrRejectLoading, setIsAcceptOrRejectLoading] = useState(false);

 const { t } = useTranslation();
 const snackbar = useSnackbar();
 const customerSupporEmail = useCustomerSupportEmail();
 const customerSupportNumber = useCustomerSupportNumber();

 const handleAcceptInvitation = async () => {
  if (data.activationToken && data.token) {
   history.push(`login?token=${data.activationToken}&workflow=activate&invitationToken=${data.token}`);

   return;
  }

  setIsAcceptOrRejectLoading(true);

  try {
   await processInvitation({ token: data.token, action: InvitationAction.ACCEPT });
   setView(InvitationViews.INVITATION_ACCEPTED);
  } catch (error) {
   snackbar.enqueueSnackbar({
    id: 'accept-invitation-fail',
    message: t('ERROR_ACCEPTING_INVITATION'),
    severity: 'error' as SeverityType,
   });
  } finally {
   setIsAcceptOrRejectLoading(false);
  }
 };

 const handleRejectInvitation = async () => {
  setIsAcceptOrRejectLoading(true);

  try {
   await processInvitation({ token: data.token, action: InvitationAction.REJECT });
   setView(InvitationViews.INVITATION_REJECTED);
  } catch (error) {
   snackbar.enqueueSnackbar({
    id: 'reject-invitation-fail',
    message: t('ERROR_REJECTING_INVITATION'),
    severity: 'error' as SeverityType,
   });
  } finally {
   setIsAcceptOrRejectLoading(false);
   setIsRejectInvitationDialogOpen(false);
  }
 };

 return (
  <>
   <Box sx={Styles.Container}>
    <Box sx={Styles.Card}>
     <Box sx={Styles.ImageWrapper}>
      <img src="assets/images/user-unknown-add.svg" width="104px" height="104px" />
     </Box>
     <Typography variant="heading100" sx={Styles.Header}>
      {t('ADD_TO_COMPANY_TITLE', { company: data.companyName })}
     </Typography>
     <Typography variant="body100" sx={Styles.Paragraph}>
      <Trans i18nKey="ADD_TO_COMPANY_BODY_PARAGRAPH_1" components={[<strong>{data.admin}</strong>, <strong>{data.role}</strong>]} />
     </Typography>
     <Typography variant="body100" sx={Styles.Paragraph}>
      {t('ADD_TO_COMPANY_BODY_PARAGRAPH_2')}
     </Typography>
     <Box sx={Styles.Actions}>
      <Button sx={Styles.Button} variant="contained" onClick={handleAcceptInvitation} disabled={isAcceptOrRejectLoading}>
       {t('ACCEPT')}
      </Button>
      <Button sx={Styles.Button} variant="outlined" onClick={() => setIsRejectInvitationDialogOpen(true)} disabled={isAcceptOrRejectLoading}>
       {t('REJECT')}
      </Button>
     </Box>
     <Typography variant="body100" sx={Styles.Paragraph}>
      {t('ADD_TO_COMPANY_BODY_PARAGRAPH_3')}
     </Typography>
     <Box>
      <Typography variant="heading300" sx={Styles.Questions}>
       {t('QUESTIONS')}
      </Typography>
      <Typography variant="body100" sx={Styles.Paragraph}>
       {t('ADD_TO_COMPANY_BODY_PARAGRAPH_4', { phoneNumber: customerSupportNumber, email: customerSupporEmail })}
      </Typography>
     </Box>
    </Box>
   </Box>
   <RejectInvitationDialog
    isOpen={isRejectInvitationDialogOpen}
    isLoading={isAcceptOrRejectLoading}
    onClose={() => setIsRejectInvitationDialogOpen(false)}
    onConfirm={handleRejectInvitation}
   />
  </>
 );
};
