import * as v from 'app/forms/control/form-validators';
import { FormStructureType } from 'app/forms/structure/form-structure-types.enum';
import { FormGroupStructure } from 'app/forms/structure/form-group.structure';

export const createLoginFormStructure = (): FormGroupStructure => ({
  type: FormStructureType.Group,
  children: {
    username: {
      type: FormStructureType.Field,
      validators: [v.required]
    },
    password: {
      type: FormStructureType.Field,
      validators: [v.required]
    }
  }
});
