import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import * as Styles from 'features/invitation/styles';

interface InvitationRejectedProps {
 data: ValidateInvitationTokenResponse;
}

export const InvitationRejected = ({ data }: InvitationRejectedProps) => {
 const { t } = useTranslation();

 return (
  <Box sx={Styles.Container}>
   <Box sx={Styles.Card}>
    <Box sx={Styles.ImageWrapper}>
     <img src="assets/images/user-unknown-rejected.svg" width="104px" height="104px" />
    </Box>
    <Typography variant="heading100" sx={Styles.Header} textAlign="center">
     {t('INVITATION_REJECTED')}
    </Typography>
    <Box>
     <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
      {t('INVITATION_REJECTED_BODY_PARAGRAPH_1', { company: data.companyName })}
     </Typography>
     <Typography variant="body100" sx={Styles.Paragraph} textAlign="center">
      {t('INVITATION_REJECTED_BODY_PARAGRAPH_2', { admin: data.admin })}
     </Typography>
    </Box>
   </Box>
  </Box>
 );
};
