import { ButtonHTMLAttributes } from 'react';
import { useOktaAuth } from '@okta/okta-react';

/**
 * Okta SSO Button Props
 *
 * @export
 * @interface SSOButtonProps
 */
export interface SSOButtonProps {
  className?: string;
  styles?: ButtonHTMLAttributes<HTMLButtonElement>;
}

/**
 * Renders the IdP redirect button
 *
 * @export
 * @param {SSOButtonProps} { className, styles }
 * @return {*}
 */
export default function LoginWithSSOButton({ className, styles }: SSOButtonProps) {

  const { authState, oktaAuth } = useOktaAuth();
  const loginClicked = async () => oktaAuth.signInWithRedirect();

  if (!authState) {
    return null;
  }

  return (
    <button
      type="button"
      className={`primaryBtnLg signin-button ${className}`}
      id="btnLogin"
      onClick={loginClicked}
      style={styles}> Sign in</button>
  );
}
