import { lazy } from 'react';

export * from './components';
export * from './types';

export const LazyInvitationLandingPage = lazy(() =>
 import(/* webpackChunkName: "Invitation", webpackPrefetch: true */ 'features/invitation/components/InvitationLanding').then(
  ({ InvitationLanding }) => ({
   default: InvitationLanding,
  })
 )
);
