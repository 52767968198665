import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import LockResetIcon from '@mui/icons-material/LockReset';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { useState } from 'react';

const actions = [
  { icon: <DoNotTouchIcon />, name: 'HMAC', qs: 'HMAC'},
  { icon: <AddModeratorIcon />, name: 'Okta', qs: 'OKTA_WIDGET' },
  { icon: <DatasetLinkedIcon />, name: 'Okta Popup', qs: 'OKTA_WIDGET', override: {name: 'override',value: 'ephemeral'}},
  { icon: <LogoDevIcon />, name: 'Okta Redirect', qs: 'OKTA_REDIRECT'},
];

export default function AuthSwitcher() {

  const isProd =  window.location.host.includes('www.navispherecarrier.com');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleActionClick = (action) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('view');
    queryParams.set('view', action.qs);

    // handle when we want to test popup window from loclhost or need an override
    // use: `?override=ephemeral`
    if (action.override) {
      queryParams.set(action.override.name, action.override.value);
    }

    window.location.search = queryParams.toString();
    handleClose();
  }

  if (isProd) 
    return null;

  return (
      <SpeedDial
        ariaLabel="DevOnly Auth Switcher"
        sx={{ position: 'fixed', bottom: 50, right: 16 }}
        icon={<LockResetIcon fontSize='large' style={{ color: 'white'}}/>}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}>
            {actions.map((action) => (
            <SpeedDialAction
                tooltipOpen
                FabProps={{ color: 'info', size: 'large'}}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleActionClick(action)}
            />
            ))}
      </SpeedDial>
  );
}
