import { LoginForm } from '@pages/login/login-form.component';
import LoginWithSSOButton from 'features/okta/components/LoginWithSSOButton';
import OktaLoginForm from 'features/okta/components/LoginWithOktaForm';
import { ChrMainLogo } from 'utils/ChrMainLogo';
import { DownloadAppButtons } from '@shared/components/download-app-buttons/download-app-buttons.component';

export const enum LoginView {
 OKTA_WIDGET = 'OKTA_WIDGET',
 OKTA_REDIRECT = 'OKTA_REDIRECT',
 HMAC = 'HMAC',
}

export const LazyLoginForm = ({ dispatchLoginWithCredentials, redirectToRegistration, sendLoginViewEvent }) => {
 return (
  <div className="row">
   <div className="col-sm-12 flex flex-center">
    <div className="box login-box login-view">
     <div className="mainLogoContainer">
      <ChrMainLogo className="logo" alt="logo" id="mainlogo" />
     </div>
     <LoginForm onSubmit={dispatchLoginWithCredentials} onRegister={redirectToRegistration} onLoginFormView={sendLoginViewEvent} />
     <DownloadAppButtons />
    </div>
   </div>
  </div>
 );
};

export const Views = {
 OKTA_WIDGET: () => <OktaLoginForm />,
 OKTA_REDIRECT: () => <LoginWithSSOButton />,
 HMAC: (props: any) => <LazyLoginForm {...props} />,
};
