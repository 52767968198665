import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import * as Styles from 'features/invitation/styles';
import CircularProgress from '@mui/material/CircularProgress';

interface RejectInvitationDialogProps {
 isOpen: boolean;
 isLoading?: boolean;
 onClose: () => void;
 onConfirm: () => void;
}

export const RejectInvitationDialog = ({ isOpen, isLoading, onClose, onConfirm }: RejectInvitationDialogProps) => {
 const { t } = useTranslation();

 return (
  <Dialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
   <DialogTitle sx={Styles.RejectDialogTitleContainer} id="alert-dialog-title">
    <Typography sx={Styles.RejectDialogTitle}>{t('REJECT_INVITATION_DIALOG_TITLE')}</Typography>
    <IconButton aria-label="close" onClick={onClose} sx={Styles.RejectDialogCloseButton}>
     <CloseIcon />
    </IconButton>
   </DialogTitle>
   <DialogContent>
    <Typography id="alert-dialog-description" variant="body1" sx={Styles.RejectDialogBody}>
     {t('REJECT_INVITATION_DIALOG_BODY')}
    </Typography>
   </DialogContent>
   <DialogActions sx={Styles.RejectDialogActions}>
    <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading}>
     {t('CANCEL')}
    </Button>
    <Button
     color="primary"
     variant="contained"
     onClick={onConfirm}
     autoFocus
     disabled={isLoading}
     startIcon={isLoading && <CircularProgress sx={Styles.CircularProgressIcon} size={16} />}
    >
     {t('CONFIRM')}
    </Button>
   </DialogActions>
  </Dialog>
 );
};
