export const OktaWidgetStringOverrides = {
  'cs': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Zadejte e-mailovou adresu.',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Váš účet je uzamčen. Prosím resetujte své heslo pomocí odkazu Zapomenuté heslo níže.',
    'account.unlock.email.or.username.placeholder': 'Email',
    'account.unlock.email.or.username.tooltip': 'Email'
  },
  'de': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Bitte geben Sie eine E-Mail-Adresse ein.',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Ihr Konto ist gesperrt. Bitte setzen Sie Ihr Passwort über den Link „Passwort vergessen“ unten zurück.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'en': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Please enter an email address',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Your account is locked. Please reset your password using the Forgot password link below.',
    'account.unlock.email.or.username.placeholder': 'Email',
    'account.unlock.email.or.username.tooltip': 'Email'
  },
  'es': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Indique una dirección de e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Tu cuenta esta bloqueada. Restablezca su contraseña utilizando el enlace ¿Olvidé mi contraseña? a continuación.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'fr': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Courriel',
    'error.username.required': 'Veuillez saisir une adresse de courriel valide',
    'password.forgot.email.or.username.placeholder': 'Courriel',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Ton compte est bloqué. Veuillez réinitialiser votre mot de passe en utilisant le lien Mot de passe oublié ci-dessous.',
    'account.unlock.email.or.username.placeholder': 'Courriel',
    'account.unlock.email.or.username.tooltip': 'Courriel'
  },
  'hu': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Adjon meg egy e-mail címet',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Fiókja zárolva van. Kérjük, állítsa vissza jelszavát az alábbi Elfelejtett jelszó hivatkozás segítségével.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'it': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Immettere un indirizzo e-mail valido',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Il tuo account è bloccato. Reimposta la tua password utilizzando il link Password dimenticata riportato di seguito.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'ja': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '電子メール',
    'error.username.required': '電子メールアドレスを入力してください',
    'password.forgot.email.or.username.placeholder': '電子メール',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'あなたのアカウントはロックされています。以下の「パスワードを忘れた場合」リンクを使用してパスワードをリセットしてください。',
    'account.unlock.email.or.username.placeholder': '電子メール',
    'account.unlock.email.or.username.tooltip': '電子メール'
  },
  'ko': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '이메일',
    'error.username.required': '이메일 주소를 입력해 주세요',
    'password.forgot.email.or.username.placeholder': '이메일',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': '귀하의 계정이 잠겨 있습니다. 아래 비밀번호 찾기 링크를 이용하여 비밀번호를 재설정해주세요.',
    'account.unlock.email.or.username.placeholder': '이메일',
    'account.unlock.email.or.username.tooltip': '이메일'
  },
  'nl-NL': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Voer een e-mailadres in',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Je account is geblokkeerd. Reset uw wachtwoord via de onderstaande link Wachtwoord vergeten.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'pl': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Wprowadź adres e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Twoje konto jest zablokowane. Zresetuj swoje hasło, korzystając z poniższego linku Zapomniałem hasła.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'pt-BR': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Insira um endereço de e-mail',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Sua conta está bloqueada. Por favor, redefina sua senha usando o link Esqueci a senha abaixo.',
    'account.unlock.email.or.username.placeholder': 'Email',
    'account.unlock.email.or.username.tooltip': 'Email'
  },
  'ro': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-mail',
    'error.username.required': 'Vă rugăm introduceți o adresă de e-mail',
    'password.forgot.email.or.username.placeholder': 'E-mail',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Contul tău este blocat. Vă rugăm să vă resetați parola folosind linkul Am uitat parola de mai jos.',
    'account.unlock.email.or.username.placeholder': 'E-mail',
    'account.unlock.email.or.username.tooltip': 'E-mail'
  },
  'ru': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Эл. почта',
    'error.username.required': 'Введите адрес эл. почты.',
    'password.forgot.email.or.username.placeholder': 'Эл. почта',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Ваш аккаунт заблокирован. Пожалуйста, сбросьте свой пароль, используя ссылку «Забыли пароль» ниже.',
    'account.unlock.email.or.username.placeholder': 'Эл. почта',
    'account.unlock.email.or.username.tooltip': 'Эл. почта'
  },
  'tr': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'E-posta',
    'error.username.required': 'Lütfen bir e-posta adresi girin',
    'password.forgot.email.or.username.placeholder': 'E-posta',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': 'Hesabınız kilitli. Lütfen aşağıdaki Şifremi unuttum bağlantısını kullanarak şifrenizi sıfırlayın.',
    'account.unlock.email.or.username.placeholder': 'E-posta',
    'account.unlock.email.or.username.tooltip': 'E-posta'
  },
  'zh-CN': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '电邮',
    'error.username.required': '请输入电子邮件地址',
    'password.forgot.email.or.username.placeholder': '电邮',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': '您的帐户已被锁定。请使用下面的忘记密码链接重置您的密码。',
    'account.unlock.email.or.username.placeholder': '电邮',
    'account.unlock.email.or.username.tooltip': '电邮'
  },
  'zh-TW': {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': '電郵',
    'error.username.required': '請輸入電子郵寄地址',
    'password.forgot.email.or.username.placeholder': '電郵',
    'password.forgot.email.or.username.tooltip': ' ',
    'error.auth.lockedOut': '您的帳戶已被鎖定。請使用下面的忘記密碼連結重設您的密碼。',
    'account.unlock.email.or.username.placeholder': '電郵',
    'account.unlock.email.or.username.tooltip': '電郵'
  }
}
