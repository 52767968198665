import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { loginWithCredentials } from 'features/security/auth.actions';
import { Page } from 'shared/components/analytics/page.component';
import { setLoginViewEvent } from 'features/analytics/redux/analytics-v2.actions';
import { MigrationPhase } from 'shared/components/banner-post-login/use-okta-migration-banner-config';

import './login.component.scss';
import { LoginView, Views } from '@pages/login/Views';
import AuthSwitcher from '@features/security/components/AuthSwitcher';
import { isOkta } from 'utils/auth';

export interface ILoginPage {
 oktaUIEnabled?: boolean;
}

export const LoginPage = ({ oktaUIEnabled = false }: ILoginPage) => {
 const [hasOktaCreds, setHasOktaCreds] = useState(false);
 const { isOktaEnabled, oktaMigrationPhase } = useFlags();

 const queryParams = new URLSearchParams(window.location.search);

 const dispatchLoginWithCredentials = (props: any) => push('/login?view=OKTA_WIDGET');
 const redirectToRegistration = useDispatcher(() => push('/registration'));
 const sendLoginViewEvent = useDispatcher(setLoginViewEvent);

 const isOktaLoginOnly = oktaMigrationPhase === MigrationPhase.OKTA_LOGIN_ONLY;

 isOkta()
  .then(result => setHasOktaCreds(result))
  .catch(err => setHasOktaCreds(false));

 /**
  * Deciding what form to show:
  * 'OKTA_WIDGET' | 'OKTA_REDIRECT' | 'HMAC'
  *
  * The default view is driven by the Okta LD flag, however while on the screen, you can
  *  update the url to select a new view as shown below.
  *  `'.../login?view=OKTA_WIDGET' | '.../login?view=OKTA_REDIRECT' | '.../login?view=HMAC'`
  * */
 const defaultView = ((isOktaEnabled || oktaUIEnabled) && hasOktaCreds && 'OKTA_WIDGET') || 'HMAC';

 // if migration phase is okta login only then view is okta_widget, else get the view from the querystring or assign the default
 const view = isOktaLoginOnly ? 'OKTA_WIDGET' : ((queryParams.get('view') || defaultView).toUpperCase() as LoginView);

 return (
  <Page name="Login">
   {Views[view]({
    dispatchLoginWithCredentials,
    redirectToRegistration,
    sendLoginViewEvent,
   })}
   {!isOktaLoginOnly && <AuthSwitcher />}
  </Page>
 );
};

export const Login = LoginPage;
