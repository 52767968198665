export const RejectDialogTitleContainer = {
 display: 'flex',
 gap: '16px',
 alignItems: 'center',
 padding: '16px 24px',
};

export const RejectDialogTitle = {
 color: '#212121',
 fontSize: '18px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.15px',
};

export const RejectDialogCloseButton = {
 color: '#212121',
};

export const RejectDialogBody = {
 color: '#212121',
 fontSize: '14px',
 fontWeight: '400',
 lineHeight: '24px',
 letterSpacing: '0.5px',
};

export const RejectDialogActions = {
 padding: '24px',
};

export const CircularProgressIcon = {
 color: 'rgba(0, 0, 0, 0.28)',
};
