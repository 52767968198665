import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FunctionComponent, useState } from 'react';

import { useOnce } from 'app/hooks/use-once.hook';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';
import { Button, Label } from 'shared/components/elements/elements.components';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { NavCarrierFormGroup } from 'app/forms/control/form-group';
import { createLoginFormStructure } from './login-form.structure';
import { Translation, TranslationJSX } from 'shared/components/translation/translation.component';
import { FormError } from 'shared/components/formatters/form-error.formatter';
import { ComponentConnectorFactory, ResourceProps } from 'store/component-connector';
import { titleCase } from 'shared/components/formatters/title-case.formatter';
import { select } from 'store/selectors/state.selectors';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { CustomerSupportNumber } from 'shared/components/customer-support/customer-support-number.component';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { Toggletip, ToggletipController } from '@chr/eds-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { AppRoute } from 'app/routesEnum';
import { Input } from './components/input.component';
import { CustomerSupportEmail } from '@shared/components/customer-support/customer-support-email.component';

interface OwnProps {
 onSubmit: (un: string, pw: string) => any;
 onRegister: () => any;
 onLoginFormView: () => any;
}

interface ConnectStateProps {
 errors: APIErrorResponse;
}

type ConnectResourceKeys = 'USERNAME' | 'PASSWORD' | 'FORGOT_USERNAME_NO_QUESTION_MARK' | 'FORGOT_PASSWORD_NO_QUESTION_MARK';

type Props = OwnProps & ResourceProps<ConnectResourceKeys> & ConnectStateProps;

export const LoginFormContainer: FunctionComponent<Props> = ({ resources, errors, ...props }) => {
 const form = useOnce(() => new NavCarrierFormGroup(createLoginFormStructure()));
 const [passwordVisible, setPasswordVisibility] = useState(false);
 const { t } = useTranslation();
 const dispatch = useDispatch();

 React.useEffect(() => {
  props.onLoginFormView();
 }, []);

 // needs to subscribe to validation changes to show errors.
 useUpdateOnFormChanges(form);

 const username = form.get('username') as NavCarrierFormControl;
 const password = form.get('password') as NavCarrierFormControl;

 const onSubmit = (e: React.FormEvent) => {
  e.preventDefault();

  if (!form.valid) {
   username.touch();
   password.touch();
   return;
  }

  props.onSubmit(username.value, password.value);
 };

 return (
  <form onSubmit={onSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
   <div className="form-fields">
    <div className={`login-input-box ${classNames('js-field-username', { 'has-error': username.hasErrors() })}`}>
     <div className="login-form-group">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '12px'}}>
        <Typography variant="heading200">
          {t('LOGIN')}
        </Typography>
        <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap', alignContent: 'center'}}>
          <MuiLink href="login?view=OKTA_WIDGET" underline="hover">
            <Typography variant="body1" sx={{ fontWeight: '600' }}>
              {t("LOG_IN_WITH_EMAIL")}
            </Typography>
          </MuiLink>
          <Toggletip
            id="log-in-with-email-tooltip"
            title={t('LOG_IN_WITH_EMAIL')}
            body={t('LOG_IN_WITH_EMAIL_TOOLTIP_BODY')}
            primaryButton={{
              text: t('LEARN_MORE'),
              onClick: () => dispatch(push(AppRoute.OKTA_FAQ))
            }}
          >
            <IconButton
              onClick={() => ToggletipController.showToggletip('log-in-with-email-tooltip')}
              sx={{
                padding: 0,
                width: 'auto',
                height: 'auto',
                color: '#545658'
              }}
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Toggletip>
        </Stack>
      </Box>
      <Label htmlFor="Username" className="required" resource="USERNAME" />
      <Input
       id="Username"
       name="Username"
       auto-id="signin_username_input"
       type="text"
       field={username}
       placeholder={resources.USERNAME}
       tabIndex={1}
      />
      <FormError dataCy="signin_email_validation_error" display={username.hasError('required')} resource="USERNAME_IS_REQUIRED" />
     </div>
     <Link to="/login/forgot-username" className="forgot-link" tabIndex={5}>
      {titleCase(resources.FORGOT_USERNAME_NO_QUESTION_MARK)}
     </Link>
    </div>

    <div className={`login-input-box ${classNames('js-field-password', { 'has-error': password.hasErrors() })}`}>
     <div className="login-form-group">
      <Label htmlFor="Password" className="required" resource="PASSWORD" />
      <Input
       id="Password"
       name="Password"
       auto-id="signin_password_input"
       type={passwordVisible ? 'text' : 'password'}
       field={password}
       placeholder={resources.PASSWORD}
       tabIndex={2}
      />
      <FormError dataCy="signin_password_validation_error" display={password.hasError('required')} resource="PASSWORD_IS_REQUIRED" />
     </div>
     <Link to="/login/forgot-password" className="forgot-link" tabIndex={6}>
      {titleCase(resources.FORGOT_PASSWORD_NO_QUESTION_MARK)}
     </Link>
    </div>
    <div className="js-field-show-password">
     <Label htmlFor="show-password" className="checkbox-inline">
      <input name="show-password" type="checkbox" onChange={e => setPasswordVisibility(e.target.checked)} />
      <Translation resource="SHOW_PASSWORD" />
     </Label>
    </div>
   </div>
   {errors && (
    <div>
     <FormError
      className="api-error"
      dataCy="signin_form_error"
      display={Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('E1030106C2A5')}
      resource="THE_USERNAME_OR_PASSWORD_YOU_ENTERED_IS_INCORRECT"
     />
     <FormError
      className="api-error"
      display={Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('E1030107C2A5')}
      resource="THIS_USER_ACCOUNT_IS_TEMPORARILY_LOCKED_TO_UNLOCK_"
      replacements={[<CustomerSupportNumber />]}
     />
     <FormError
      className="api-error"
      display={Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('E1030105C2A5')}
      resource="THIS_USER_ACCOUNT_IS_NO_LONGER_ACTIVE_FOR_MORE_INF"
      replacements={[<CustomerSupportNumber />]}
     />
     <FormError
      className="api-error"
      display={Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('E1030104C2A5')}
      resource="THIS_USER_ACCOUNT_HAS_NOT_YET_BEEN_APPROVED"
     />
     <FormError
      className="api-error"
      display={Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('limitedUser')}
      resource="LIMITED_USER_ERROR_MESSAGE"
      replacements={[<span>{Boolean(errors) && errors instanceof APIErrorResponse ? errors?.errors[0]?.message : ''}</span>]}
     />
     {Boolean(errors) && errors instanceof APIErrorResponse && errors.includes('limitedUser') && (
      <p className="font-size-xs">
       <TranslationJSX
        resource="LIMITED_USER_HELP_MESSAGE"
        replacements={[<CustomerSupportEmail className="font-size-xs" />, <CustomerSupportNumber />]}
       />
      </p>
     )}
     <FormError
      className="api-error"
      display={Boolean(errors) && !(errors instanceof APIErrorResponse)}
      resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE"
     />
    </div>
   )}

   <div className="buttons">
    <Button type="submit" btnPrimary btnBlock id="btnLogin" resource="LOG_IN" tabIndex={3} auto-id="signin_submit_login_button" />
    <Button btnDefault btnBlock id="btn-register" onClick={props.onRegister} resource="CREATE_ACCOUNT" tabIndex={4} />
   </div>
  </form>
 );
};

export const LoginForm = ComponentConnectorFactory<OwnProps, ConnectStateProps, {}, ConnectResourceKeys>()
 .withResources<ConnectResourceKeys>('USERNAME', 'PASSWORD', 'FORGOT_USERNAME_NO_QUESTION_MARK', 'FORGOT_PASSWORD_NO_QUESTION_MARK')
 .combineStateSelectors(select.auth.errors)
 .connect(LoginFormContainer);
