import { Translation, TranslationJSX } from '../translation/translation.component';
import './download-app-buttons.component.scss';

export const DownloadAppButtons = () => (
 <div className="download-app-buttons">
    <p className="download-text">
        <TranslationJSX
            resource="DOWNLOAD_THE_NAVISPHERE_CARRIER_APP_ON_YOUR_IPHONE_0_OR_ANDROID_0_DEVICE"
            replacements={[<sup>&reg;</sup>, <sup>&reg;</sup>]}
        />
    </p>
    <div className="flex flex-center button-box ">
        <a className="app-button space-outer-right-md" href="https://itunes.apple.com/us/app/navisphere-carrier/id1089613477">
            <span className="visually-hidden">
            <Translation resource="DOWNLOAD_ON_THE_MAC_APP_STORE" />
            </span>
            <img alt="Download on the App Store" src="/assets/images/download-on-the-app-store.webp" />
        </a>
        <a className="app-button" href="https://play.google.com/store/apps/details?id=com.chrobinson.navispherecarrier">
            <span className="visually-hidden">
            <Translation resource="GET_IT_ON_GOOGLE_PLAY" />
            </span>
            <img alt="Download on the Google Play" src="/assets/images/google-play-badge.webp" />
        </a>
    </div>
 </div>
);
